<template>
  <div class="wrapper">
    <transition name="fade">
      <div class="backdrop" v-if="this.loading">
        <img src="../assets/logo.png">
        <div class="loader"></div>
      </div>
    </transition>
    <div class="reveal mx-auto" id="box">
      <img src="../assets/logo.png" class="logo">
      <Menu class="menu" />
      <div class="slides">
        <TitleSlide class="animate__animated animate__fadeIn" :agent="this.agent" />
        <AgentsCard class="animate__animated animate__fadeIn" :agent="this.agent" />
        <Company class="animate__animated animate__fadeIn"/>
        <Services class="animate__animated animate__fadeIn"/>
        <SevenReasons class="animate__animated animate__fadeIn"/>
        <BuyingSteps class="animate__animated animate__fadeIn"/>
        <SellingSteps class="animate__animated animate__fadeIn"/>
        <Partners class="animate__animated animate__fadeIn"/>
        <Novostr class="animate__animated animate__fadeIn"/>
        <MarketingPlan class="animate__animated animate__fadeIn"/>
        <Gic class="animate__animated animate__fadeIn"/>
      </div>
</div>

<!-- modals -->
<div class="modal fade" id="reviewsModal" ref="reviewsmodal">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal"
        v-on:click="startscroll()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <Reviews :agent="this.agent" />
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="certsModal" ref="certsmodal">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal"
        v-on:click="startscroll()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <Certs :agent="this.agent" />
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="objectsModal" ref="objectsmodal">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal"
        v-on:click="startscroll()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <Objects :agent="this.agent" />
      </div>
    </div>
  </div>
</div>

<!-- scroll to top -->
  <button type="button" class="btn btn-primary" id="scrollToTopBtn"
  v-if="this.scrollvisible" v-on:click="this.scrollToTop">☰</button>
</div>
</template>

<script>
import Reveal from 'reveal.js';
import axios from 'axios';
import $ from 'jquery';
import Menu from './Menu.vue';
import AgentsCard from './AgentsCard.vue';
import TitleSlide from './TitleSlide.vue';
import SevenReasons from './SevenReasons.vue';
import Company from './Company.vue';
import BuyingSteps from './BuyingSteps.vue';
import SellingSteps from './SellingSteps.vue';
import Partners from './Partners.vue';
import Services from './Services.vue';
import Novostr from './Novostr.vue';
import MarketingPlan from './MarketingPlan.vue';
import Gic from './Gic.vue';
import Reviews from './Reviews.vue';
import Certs from './Certs.vue';
import Objects from './Objects.vue';

export default {
  name: 'FullScreen',
  data() {
    return {
      scrollvisible: false,
      agent: '',
      loading: '',
    };
  },
  methods: {
    async FetchAgent(id) {
      const url = 'https://weather.matveymatvey.com/getagent';
      // const url = 'http://127.0.0.1:5000/getagent';
      this.loading = true;
      const { data } = await axios.get(`${url}/${id}`);
      this.agent = data;
      this.loading = false;
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },

    scrollListener() {
      const rootElement = document.documentElement;
      // do something on scroll
      const scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
      if ((rootElement.scrollTop / scrollTotal) > 0.01) {
        // show button
        this.scrollvisible = true;
      } else {
        // hide button
        this.scrollvisible = false;
      }
    },

    startscroll() {
      Reveal.configure({ mouseWheel: true });
    },

  },
  mounted() {
    $(this.$refs.reviewsmodal).on('hidden.bs.modal', this.startscroll);
    $(this.$refs.certsmodal).on('hidden.bs.modal', this.startscroll);
  },

  created() {
    this.FetchAgent(this.id);
    window.addEventListener('scroll', this.scrollListener);
  },

  props: ['id'],
  components: {
    Menu,
    TitleSlide,
    AgentsCard,
    SevenReasons,
    Company,
    BuyingSteps,
    SellingSteps,
    Partners,
    Services,
    Novostr,
    MarketingPlan,
    Gic,
    Reviews,
    Objects,
    Certs,
  },
};
</script>

<style>

.wrapper {
  width: 100vw;
  height: 100vh;
}

 .backdrop {
   background-color: #e9ecef;
   width: 100vw;
   height: 100vh;
   z-index: 100000;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   text-align: center;
   top: 0;
   left: 0;
 }

 .loader {
   margin-top: 20px;
   display: block;
   border: 8px solid #f3f3f3;
   border-top: 8px solid #015FAE;
   border-radius: 50%;
   width: 80px;
   height: 80px;
   animation: spin 2s linear infinite;
 }

 .fade-enter-active, .fade-leave-active {
   transition: opacity .5s;
 }
 .fade-enter, .fade-leave-to {
   opacity: 0;
 }

 @keyframes spin {
   0% { transform: rotate(0deg); }
   100% { transform: rotate(360deg); }
 }

.reveal-viewport {
  background:url('../assets/bg_1920.jpg');
  background-size: cover;
  background-repeat: repeat-y;
}

.logo {
  margin-left:70px;
  width: 230px;
  margin-top: 20px;
  display: block;
}

.menu {
  display: inline-block;
}

.reveal .slides {
  text-align: unset !important;
}

.reveal {
  max-width: 1200px;
  padding:0px;
  /* max-height: 768px; */
  overflow: unset !important;
}

.slides {
  max-width: 1017px;
  padding: 0;
  margin: 0 !important;
  margin-left: 30px !important;
}

.reveal .slides + .menubar {
bottom: 0;
border-top: 1px solid rgba(150, 150, 150, .5);
border-bottom: none;
}

.reveal .menu ul,
.reveal ul.menu {
list-style-type: none;
margin-left: 0
}

.reveal .menu ul li,
.reveal ul.menu li {
display: inline-block
}

.reveal .menu ul li a.active,
.reveal .menu ul li.active a,
.reveal ul.menu li a.active,
.reveal ul.menu li.active a {
color: #000;
pointer-events: none
}

.reveal .menu ul a,
.reveal ul.menu a {
font-size: 1rem;
padding: .5rem 1rem;
display: inline-block;
vertical-align: top
}

.print-pdf .reveal .menu ul a.active,
.print-pdf .reveal .menu ul.active a,
.print-pdf .reveal ul.menu a.active,
.print-pdf .reveal ul.menu.active a {
color: inherit
}

.slides {
  margin-top: 64px;
}

.reveal .slides > section.present, .reveal .slides > section > section.present {
  min-height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  /* justify-content: center !important; */
  position: absolute !important;
  top: 0 !important;
}

.print-pdf .reveal .slides > section.present, .print-pdf .reveal
.slides > section > section.present {
  min-height: 770px !important;
  position: relative !important;
}

.title {
  font-family: Gilroy-SemiBold, sans-serif;
  text-transform: uppercase;
  margin-right: 10px;
  font-size: 2em;
  line-height: 25px;
}

/* for mobile */
 @media screen and (min-width: 300px) and (max-width: 500px) {
   body {
     background:url('../assets/mobilebg.jpg');
     background-position: left !important;
     background-repeat: no-repeat;
     background-attachment: fixed;
     background-size: auto !important;
   }

   .wrapper {
     height: unset !important;
   }

   .menu {
     display: none;
   }
   .reveal {
     overflow: auto;
     max-width: 340px;
   }
   .reveal .slides {
     position: relative;
     margin-left: 0px !important;
   }
   .reveal .slides>section, .reveal .slides>section>section {
     display: block;
     position: relative;
     overflow: auto;
     padding-top: 0px;
     pointer-events: all !important;
   }
   .logo {
     margin-left: auto;
     margin-right: auto;
     max-width: 317px;
     max-height: 68px;
     margin-top: 43px;
   }
   .title {
     font-family: Gilroy-SemiBold;
     text-transform: uppercase;
     margin-right: auto;
     margin-left: auto;
     text-align:center !important;
     font-size: 32px !important;
     line-height: unset !important;
     margin-top: 35px;
   }

   .backdrop {
     touch-action: none;
     pointer-events: none;
   }

   #scrollToTopBtn {
     background-color: white;
     color: #004085;
     opacity: 0.7;
     border: none;
     font-size: 24px;
     font-weight: bolder;
     cursor: pointer;
     position: fixed;
     right: 10px;
     bottom: 20px;
     z-index: 1000;
   }
 }

 /* 125 scale */
 @media (min-width: 1000px) and (max-width: 1201px)
 and (-webkit-min-device-pixel-ratio: 1.25), (resolution: 120dpi) {
   #box {
     transform: scale(0.85);
     margin-top: -50px;
   }
  }
/* 1600-900 */
 @media (min-width: 1599px) and (max-width: 1600px)
 and (-webkit-min-device-pixel-ratio: 1) {
   #box {
     transform: scale(0.90);
   }
  }
/* retina */
@media (min-width: 1100px) and (max-width: 1201px)
and (-webkit-min-device-pixel-ratio: 2) {
  #box {
    transform: scale(0.75);
    margin-top: -65px;
  }
  }
</style>
