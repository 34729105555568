<template>
  <section id="Company" data-menu-title="Company">
    <h1 class="title text-right">О компании</h1>
    <div class="company-container">
      <div class="bg1">
        <div class="bg2">
          <div class="d-flex flex-column firstcol p-3">
            <div class="px-2 item">
              <p class="itemtitle">
                <span class="number"> {{ yearsOnMarket }}</span
                >&nbsp;{{ yearWord }}
              </p>
              <p class="itemdescription">
                Работаем на рынке недвижимости России с 1993 года.
              </p>
            </div>

            <div class="px-2 item ">
              <p class="itemtitle "><span class="number"> 16</span>&nbsp;компаний</p>
              <p class="itemdescription">
                В Санкт-Петербурге – единые стандарты обслуживания, технологии, информационная
                система.
              </p>
            </div>

            <div class="px-2 item">
              <p class="itemtitle"><span class="number">18</span>&nbsp;побед</p>
              <p class="itemdescription">
                <b>«КАИССА»</b> – профессиональный конкурс Ассоциации Риэлторов Санкт-Петербурга и
                Ленинградской области.
              </p>
            </div>
            <div class="px-2 itemlong">
              <p class="itemtitle"><span class="number">1</span>&nbsp;команда</p>
              <p class="itemdescription">
                Единственная российская<br />компания, которая стала<br />
                победителем международного конкурса European Property Awards<br />
                в номинации «Лучшее агентство недвижимости с количеством <br />офисов от 5 до 20».
              </p>
            </div>
          </div>

          <div class="d-flex flex-column seccol p-3">
            <div class="px-2 item">
              <p class="itemtitle"><span class="number">8</span>&nbsp;наград</p>
              <p class="itemdescription">
                <b>«CREDO»</b> – национальный конкурс в сфере недвижимости, строительства и ипотеки.
              </p>
            </div>

            <div class="px-2 item">
              <p class="itemtitle"><span class="number">6</span>&nbsp;премий</p>
              <p class="itemdescription">
                <b>«Профессиональное признание»</b> – всероссийский конкурс Российской Гильдии
                Риэлторов.
              </p>
            </div>
            <div class="px-2 item">
              <p class="itemtitle"><span class="number">4</span>&nbsp;титула</p>
              <p class="itemdescription">
                В конкурсе <b>«Доверие потребителя»</b> четырежды были признаны<br />
                «Риэлторским агентством, пользующимся наибольшим<br />
                доверием потребителей на рынке Санкт-Петербурга».
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Company',
  data() {
    return {
      year: new Date().getFullYear(),
      agent: '',
      loading: '',
    };
  },
  methods: {},
  computed: {
    yearsOnMarket() {
      return this.year - 1993;
    },
    yearWord() {
      const years = this.yearsOnMarket;
      if (years % 10 === 1 && years % 100 !== 11) {
        return 'год';
      }
      if ([2, 3, 4].includes(years % 10) && ![12, 13, 14].includes(years % 100)) {
        return 'года';
      }
      return 'лет';
    },
  },
  mounted() {},

  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.firstcol {
  position: absolute;
  z-index: 1;
}

.seccol {
  left: 370px;
  position: relative;
  z-index: 1;
}

.company-container {
  padding: 0px;
  max-width: 1017px;
  margin: 0;
  margin-top: 55px;
  margin-left: 40px;
}

.bg2 {
  background: rgba(255, 255, 255, 0.55);
  height: 610px;
  width: 777px;
}

.bg1 {
  background: url(../assets/company_bg.png);
  background-repeat: no-repeat;
  background-position: -220px -70px;
  height: 610px;
}

.item {
  width: 350px;
}

.itemtitle {
  font-family: gilroy-extrabold, sans-serif;
  text-transform: uppercase;
  font-size: 36px;
}

.itemdescription {
  font-family: gilroy-medium, sans-serif;
  font-size: 18px;
}

.itemlong {
  width: 740px;
}

.number {
  background-color: #365ea8;
  color: white;
  padding: 5px;
  width: 52px;
  height: 44px;
  display: inline-block;
  text-align: center;
}

@media (max-width: 500px) {
  h1 {
    margin-bottom: 50px;
  }

  .bg1 {
    height: 100%;
    background: unset;
  }

  .bg2 {
    height: 100%;
    background: rgba(255, 255, 255, 1);
    width: 100%;
  }

  .company-container {
    padding: 0;
    margin: 0;
  }

  .firstcol {
    position: relative;
    flex-wrap: wrap;
    padding-bottom: 0px !important;
  }

  .seccol {
    position: relative;
    flex-wrap: wrap;
    left: 0;
    padding-top: 0px !important;
  }

  .item {
    width: 100%;
  }

  .number {
    height: 100%;
    font-size: 24px;
  }

  .itemtitle {
    font-size: 20px;
  }

  .itemlong {
    width: 100%;
  }

  .fragment {
    visibility: visible !important;
    opacity: unset !important;
  }
}
</style>
