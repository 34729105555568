<template>
  <div class="container mt-5 reviews" v-if="agent.reviews">
    <h1 class="title mb-5">Отзывы</h1>
    <a
      role="button"
      class="btn btn-primary mr-3"
      rel="noreferrer"
      target="_blank"
      :href="advecscom_link"
    >
      Оставить отзыв
      <font-awesome-icon :icon="['fas', 'external-link-alt']" class="ml-1" />
    </a>
    <transition-group name="list">
      <div class="my-3 p-3 bg-light" v-for="(item, index) in agent.reviews" :key="index">
        <p>{{ item.text }}</p>
        <small v-if="item.reviewer">{{ item.reviewer }}, </small><small>{{ item.date }}</small>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'Reviews',
  props: ['agent'],
  computed: {
    advecscom_link() {
      return `https://advecs.com/agents/${this.agent.id}`;
    },
  },
};
</script>

<style scoped>
body {
  background-image: unset;
  background-color: lightgrey;
}

.container {
  max-width: 700px;
}

</style>
